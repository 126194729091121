<template>
    <div v-if="neighborhoodItem">
        <div class="row d-flex justify-content-center">
            <div class="col-1 side-strip d-flex justify-content-center align-items-start">
                <img class="pt-2" :src="require('@/assets/images/icons/Map.svg')" style="max-width: 100%;"/>
            </div>
            <div class="col-11 p-0" style="max-height:500px;">
                <b-img v-if="neighborhoodItem.main_photo != ''" :src="`/api/user/v1/image/${neighborhoodItem.main_photo}`" style="max-height:500px; width: 100%; object-fit: cover; object-position: center;"/>
                <b-img v-else :src="require('@/assets/images/esoseska/no-image.jpg')" fluid style="max-height:500px;"/>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-4">
                <div class="title">
                    {{neighborhoodItem.title}}
                </div>
            </div>
            <div class="col-8" v-if="neighborhoodItem.description_list">
                <div v-for="(item, id) in neighborhoodItem.description_list" :key="'neighborhoodItem_description' + id">
                    <div class="title-list">
                        + {{item.title}}
                    </div>
                    <div class="description-list mb-1">
                        {{item.description}}
                    </div>
                </div>
            </div>
        </div>

        <div>

            <div class="mt-2">
                <div class="d-flex justify-content-center">
                    <b-img v-if="neighborhoodItem.main_photo != ''" :src="`/api/user/v1/image/${neighborhoodItem.main_photo}`" style="height: 60vh; max-height:60vh; width: 100%; object-fit: cover; object-position: center;" />
                    <b-img v-else :src="require('@/assets/images/esoseska/no-image.jpg')" fluid style="max-height:500px;"/>
                </div>
            </div>

            <div v-if="photos && photos.length > 0">
                <swiper :swiper-data="photos" :pagination="false" :heightImg="200" type="imageGallery"/>
            </div>
        </div>

        <div class="mt-2">
            <div v-html="neighborhoodItem.description" />
        </div>

        <router-link :to="{name: 'real-estate', params: { real_estate_id: realEstateId  }}" v-if="preview !== true">
            <div class="d-flex align-items-center mt-3">

                <div>
                    <img :src="require('@/assets/images/icons/left_arrow.svg')" style="max-height: 25px; margin-right: 10px;"/>
                </div>
                Nazaj na nepremičnino
            </div>
            
        </router-link>
        <a href="/admin/neighborhoods" v-else>
            <div class="d-flex align-items-center mt-3"  >
                <div>
                    <img :src="require('@/assets/images/icons/left_arrow.svg')" style="max-height: 25px; margin-right: 10px;"/>
                </div>
                Nazaj na seznam sosesk
            </div>
        </a>

    </div>

</template>

<script>
    import { BImg } from 'bootstrap-vue'
    import Swiper from '../../Components/Swiper'

    export default {
        components:{
            BImg,
            Swiper
        },
        data() {
            return {
                preview: false,
                realEstateId: null,
                neighborhoodId: null,
                neighborhoodItem: null,
                photos: []
            }
        },
        methods:{
            setBreadcrumbTitle(title) {
                const breadCrumbIndex = this.$route.meta.breadcrumb.findIndex(x => x.isCustomBreadcrumb)
                
                if (breadCrumbIndex !== -1) {
                    this.$route.meta.breadcrumb[breadCrumbIndex].text = title

                    this.$root.$emit('force-update-breadcrumbs')
                } 
            },
            async loadNeighborhood() {
                try {
                    let addToUrl = ''

                    if (this.preview === true) {
                        addToUrl = 'preview/'
                    }

                    const res = await this.$http.get(`/api/user/v1/neighborhoods/${addToUrl}${this.realEstateId}`)
                    this.neighborhoodItem = res.data
                    this.neighborhoodId = res.data.id
                    this.setBreadcrumbTitle(this.neighborhoodItem.title)

                    for (const item of this.neighborhoodItem.additional_photos) {
                        this.photos.push({itemId: `${item}_idSlike`, src: `/api/user/v1/image/${  item}`, thumbnail: `/api/user/v1/image/thumbnail_${  item}`, thumbnailHeight: '100px'})
                    }
                } catch (error) {
                    this.$printError(`Pri nalaganju soseske je prišlo do napake!\n${error.message}`)
                }
            }
        },
        mounted() {
            if (this.$route.query.preview) {
                if (this.$route.query.preview === 'true') {
                    this.preview = true
                }
            }

            this.loadNeighborhood()
        },
        created() {
            this.realEstateId = this.$route.params.real_estate_id
        },
        beforeRouteLeave(to, from, next) {
            // before leaving news page, reset breadcrumb title to empty
            this.setBreadcrumbTitle('')
            next()
        }
    }
</script>

<style scoped>
    .side-strip{
        background: #72A5D8;
    }

    .title{
        font-size: 2rem;
        font-weight: 900;
        max-width: 80%;
    }

    .title-list{
        font-size: 1.5rem;
        font-weight: 900;
        margin-bottom: 10px;
    }
</style>